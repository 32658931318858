import React      from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Layout     from "../components/layout"
import Booking    from '../components/booking';
import ParaHead   from "../components/para_head"

const TXT2 = <p>
							Preise für 1 bis 2 Personen:<br />
							- Bis 3 Tage: 35€/Tag<br />
							-  Ab 4 Tage: 30€/Tag<br />
							<b>Zahlungsmöglichkeiten</b><br />
							Barzahlung<br /></p>
const TXT3 = <p>
							<b>Mietbedingungen</b><br />
							Gesamtzahlung bei Anreise<br />
							keine Kaution<br />
							Anreisetag: Täglich - früheste Anreisezeit: 13:00 Uhr<br />
							Abreise: bis spätestens 10:00 Uhr<br /><br />
						  Wohnzimmer, Küche, Schlafzimmer und Bad (siehe Bilder)<br />
						  Waschmaschine zur Mitbenutzung.<br />
							Großer Garten.<br />
							Rauchen und Mitbringen von Tieren nicht erwünscht.<br />
							Incl. Bettwäsche, Handtücher und Endreinigung.<br />
							Es wird keine Kurtaxe erhoben.<br />
							Telefon, Internetanschluß, Heizung, Kurtaxe, alles inkl.</p>

const Reservation = () => (
	<Layout head_gif = "reservation_n_bhb.png">
		 <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;<a href="mailto:fmeierhoff@gmx.de">
		 <button type="button" className="btn btn-outline-danger">Buchungs-Anfrage per Mail</button></a>
		 <ParaHead txt="Kalender" />
		 <h4>(Kalender benötigt etwas Zeit zur Aktualisierung !)</h4>
			 <Booking/>
		 <ParaHead txt="Preise" />
	   	{TXT2}
		 <ParaHead txt="Beschreibung" />
 		   {TXT3}
	</Layout>
);

export default Reservation;
